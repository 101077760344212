.page {
  max-width: 1160px !important;

  .article-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    /** needed for pull-up-mobile */
    background: white;
    padding-bottom: 2em;

    .title {
      //@include from($tablet) {
        margin-top: 0.7em;
      //}
      margin-bottom: 0;
    }

    .subtitle {
      font-style: italic;
      font-weight: unset;
    }
  }

  &:not(.pull-up-desktop) {
    margin-bottom: $spacing-6;
  }
}
