.image {
  figcaption {
    margin-top: 1.25em;

    &, .title {
      color: #2B2E34;
    }
  }
}

.image.is-issue figcaption {
  text-align: center;

  span {
    display: block;
    line-height: 1;
  }

  span:nth-child(1) {
    margin-bottom: .25em;
  }

  span:nth-child(2) {
    margin-bottom: .15em;
  }

  span:nth-child(3) {
    font-style: italic;
  }
}

.image.is-placeholder {
  background: #d0d0d0;
}

.image.has-border {
  > img,
  > picture {
    border: 1px solid #000000;
  }
}

.image-author {
  position: absolute;
  display: inline-flex;
  color: white;
  text-shadow: 1px 1px black;

  &::before {
    content: '©\00a0';
  }

  .image.has-author-top-right & {
    top: .5rem;
    right: .5rem;
    transform: rotate(90deg) translate(100%);
    transform-origin: 100% 0;
  }
}
