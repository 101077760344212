.block.is-type-paragraph,
.block.is-type-paragraph-with-dropcap,
.block.is-type-html {
  font-size: $size-text;

  &.is-reference h4,
  h2 {
    font-size: $title-size-7;
    font-family: $family-secondary;
    text-align: center;
    margin-top: 5rem;
    line-height: 1.35;

    &:first-child {
      margin-top: $size-text/2.25;
    }
  }

  hr {
    border-top: 1px solid black;
    margin-top: $spacing-5;
    margin-bottom: $spacing-6;
  }
}

.block.is-type-html {
  // legacy footer
  .field-field-fusznote {
    border-top: 2px solid black;
    margin: 2rem 0 0;
    padding: 2rem 0 0;
    font-size: 1rem;
    color: $grey-darker;
  }
}

.block.is-type-paragraph-with-dropcap,
.block.is-type-html.is-first.is-last {
  p:first-child::first-letter {
    $targetHeight: 131px; //measured from ascent of first line to baseline of 4th line
    $unitsPerEmCrimson: 1024;
    $capHeightCrimson: 587;
    font-size: $unitsPerEmCrimson/$capHeightCrimson * $targetHeight;
    float: left;
    display: block;
    margin-right: .1em;
    // firefox ignores line-height so change this
    // in order to modify chrom(e|ium)’s behaviour
    line-height: 0.5;
    initial-letter: 3;
    margin-top: .04em;
  }
}

.block.is-type-blockquote,
.content blockquote {
  margin-top: $spacing-5;
  border: solid black;
  border-width: 6px 0 2px;
  padding: 1.5rem;
  font-size: $title-size-5;
  text-align: center;
  font-family: $family-secondary;
  background-color: transparent;

  &:not(:last-child) {
    margin-bottom: $spacing-6;
  }

  @include until($tablet) {
    font-size: $title-size-7;
    border-width: 4px 0 2px;
  }
}

.block.is-type-section {
  border-top: 1px solid black;
  margin: 0;

  > .title {
    text-align: left;
    color: #a0a0a0;

    @include until($tablet) {
      text-align: center;
    }
  }
}

.block.is-type-reader-note {
  .reader-note {
    color: black;
    display: block;
  }
}

.block.is-type-explanation,
.block.is-type-column {
  margin-bottom: 2rem;

  .title {
    margin: 0 auto 1.5rem;
  }

  & + & {
    margin-top: -2rem;
  }
}

.block.is-type-column {
  border-bottom: 1px solid black;
  border-top: 1px solid black;

  .content {
    font-size: $size-8;
    line-height: 1.263;

    p:not(:last-child) {
      margin-bottom: 1.263em;
    }
  }

  @include from($tablet) {
    .content.has-columns {
      padding: 0;
      column-count: 2;
      column-gap: 1rem;
    }
  }
}

.block.is-type-explanation {
  background: #ebebeb;
  padding: $spacing-5 $spacing-3;

  .content {
    font-size: $size-8;
    line-height: 1.263;
  }
}

.block.is-type-embed iframe {
  width: 100%;
}

.block.is-type-review {
  border: solid black;
  border-width: 1px 0;
  margin-bottom: $spacing-5;
}
