.bildtheorie {
  &, .navbar.is-foreground, article, .page header, .title, .teaser, .teaser:hover,
  .block.is-type-reader-note .reader-note, .image figcaption, .article-header {
    color: #808080;
    --navbar-item-color: #808080;
    --navbar-item-active-color: #808080;
    --navbar-active-color: #808080;
    background-color: #282828;
  }

  &, a, .subtitle{
    color: #808080;
  }

  .breadcrumb {
    --breadcrumb-active-color: #{$grey-light};
  }
}

.bildtheorie {
  .bt-1, .bb-1, .image.has-border > img, .navbar.is-expanded .navbar-start, .aside:not(:last-child), .block.is-type-column, .grid > .preview, .block.is-type-blockquote, .content blockquote, .rich-text > blockquote, footer{
    border-color: #808080;
  }
  article.article{ 
    section.is-type-paragraph.is-last > p:last-of-type::after,
    section.is-type-html.is-last > p:last-of-type::after,
    section.is-type-paragraph-with-dropcap.is-last > p:last-of-type::after{ 
      background: url('../images/logo-short-grey.svg') no-repeat;
      background-position: bottom -0.1em left 0;
      background-size: auto 0.9em;
    }
  }
}

.bildpolitik {
  article.article section.is-type-paragraph.is-last > p:last-of-type::after,
  section.is-type-html.is-last > p:last-of-type::after,
  section.is-type-paragraph-with-dropcap.is-last > p:last-of-type::after{ 
    display:none;
  }
}
