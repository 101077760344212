.contact .content {

  .container {
    font-size: $size-7;
    max-width: 964px !important;
  }

  h2 {
    font-size: $size-6;
    font-family: $family-secondary;
    text-align: center;
    margin-bottom: $spacing-1;
    line-height: 1.33;
    hyphens: initial;

    @include until($tablet) {
      font-size: $title-size-7;
      margin-top: $spacing-4;
      margin-bottom: $spacing-5;
    }
  }

  h2:first-child {
    margin-top: 0;
  }

  hr {
    border-top: 1px solid black;
    margin-top: $spacing-4;
  }

  ul li {
    display: inline;
    margin-left: 0.5em;

    &:not(:first-child):before {
      content: " | ";
      margin-right: 0.5em;

    }
  }

  ul {
    margin: 0 auto;
    text-align: center;
  }

  .has-text-centered {
    max-width: 620px;
    margin: auto;
  }
}

.contact form .textarea {
  min-height: 26rem;
}
