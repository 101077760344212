.search_page {
  .page {
    margin-bottom: 0;
  }

  h2.subtitle {
    color: #676767;
  }

  .result {
    display: flex;
    gap: 3em;

    @include until($tablet) {
      gap: 1em;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .image {
      @include until($tablet) {
        width: 150px;
      }

      @include from($tablet) {
        min-width: 185px;
      }
    }
    .issue-number{
      justify-content: left;
      font-style: unset;
    }

    .issue-number, .publication-date {
      color: #A0A0A0;
    }

    .result-text {
      display: flex;
      flex-direction: column;
      @include from($tablet) {
        margin-top: -0.2em;
        margin-bottom: -0.6em;
      }
    }

    .article-authors {
      order: 1;
      display: unset;
      font-size: unset;
      text-align: unset;
    }

    .article-title {
      order: 2;
      font-weight: bold;
    }

    .article-subtitle {
      order: 3
    }

    .result-article {
      margin-top: auto;
      padding-top: 1em;
      display: flex;
      flex-direction: column;
    }
  }

  .no-result-query {
    color: #A0A0A0;
  }

  .pagination-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;

    .pagination-button-next {
      margin-left: auto;
    }
  }
}
