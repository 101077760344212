.issue {
  .article-column {
    column-gap: $size-7;
    row-gap: $size-5;
  }

  .issue-number {
    font-size: $size-6;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
