.issue-archive .page{
  padding-bottom: $spacing-4
}
.issue-archive .cover {
  position: relative;

  .header {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @include until($tablet) {
    .image {
      padding-bottom: 1em;
    }
  }

  .title {
    font-size: $size-text;
    line-height: 1em;
    margin-bottom: unset;
  }

  .subtitle {
    font-size: $size-8;
    font-weight: unset;
    margin-bottom: unset;
  }

  .date {
    font-size: $size-8;
    font-style: italic;
  }

  .order-now {
    margin-top: auto;

    @include until($tablet) {
      margin-top: $spacing-1;
    }
  }

  .info {
    text-align: center;
  }

  .button {
    height: 1.5em;
  }

  @include from($tablet) {
    .info {
      display: none;
      padding: 2em;
      border: 1px solid;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:hover .info {
      background-color: #ebebeb;
      display: block;
    }
  }
}

