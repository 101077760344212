.home_page {
  .is-33_66 .image img {
    @include from($desktop) {
      width: 275px;
    }
  }

  .is-33_66 .article-header {
    padding-bottom: 0;
    text-align: center;

    @include from($desktop) {
      min-height: 360px;
    }
  }

  .is-33_66 .article-header h2 {
    font-size: $title-size-4;
    line-height: 1.33;
  }

  .is_lead_article .article-header h2 {
    font-size: $title-size-5;
    line-height: $line-height-5;
  }

  .is-33_66 .preview-authors {
    margin-bottom: 3px;
    margin-top: -8px;
  }

  .page .is-type-articles {
    border-bottom: solid 1px black;
  }

  .page .is-type-articles:last-child {
    border-bottom: none;
  }

  .page > section:last-child section {
    border-bottom: none !important;
  }

  .page {
    margin-bottom: 0;
  }

}
