.preview {
  color: $text;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 2rem;
  border-bottom: 1px solid black;

  .image {
    margin-bottom: 5.5%;
  }

  .grid > & {
    border-bottom: 1px solid black;
  }

  &:last-child {
    border-bottom: none;
  }

  @include from($desktop) {
    .grid.is-equal-3-desktop > &:nth-child(3n + 1):nth-last-child(-n + 3),
    .grid.is-equal-3-desktop > &:nth-child(3n + 1):nth-last-child(-n + 3) ~ .preview {
      border-bottom: none;
    }
    .grid.is-equal-4-desktop > &:nth-child(4n + 1):nth-last-child(-n + 4),
    .grid.is-equal-4-desktop > &:nth-child(4n + 1):nth-last-child(-n + 4) ~ .preview {
      border-bottom: none;
    }
    .grid.is-equal-2-desktop > &:nth-child(2n + 1):nth-last-child(-n + 2),
    .grid.is-equal-2-desktop > &:nth-child(2n + 1):nth-last-child(-n + 2) ~ .preview {
      border-bottom: none;
    }
  }


  @include tablet-only {
    .grid.is-equal-3-tablet > &:nth-child(3n + 1):nth-last-child(-n + 3),
    .grid.is-equal-3-tablet > &:nth-child(3n + 1):nth-last-child(-n + 3) ~ .preview {
      border-bottom: none;
    }
    .grid.is-equal-2-tablet > &:nth-child(2n + 1):nth-last-child(-n + 2),
    .grid.is-equal-2-tablet > &:nth-child(2n + 1):nth-last-child(-n + 2) ~ .preview {
      border-bottom: none;
    }
  }
}

.preview-header {
  text-align: center;
}

.preview-header-text {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  min-height: 9rem;

  .preview-authors {
    order: 1;
  }

  .title {
    order: 2;
  }

  .preview-subtitle {
    order: 3;
    line-height: 1.26;
  }

  .preview.is-small & {
    min-height: 11rem;
  }

  .preview.is-small.has-no-author & {
    min-height: 8rem;
  }

  @include until($tablet) {
    .grid.is-equal-1 .preview & {
      min-height: 0;
      margin-bottom: $spacing-6;
    }
  }
}


.preview-authors {
  font-style: italic;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: .1em;
  font-size: $size-7;

  &:empty::after {
    content: '\00a0';
  }

  @include until($tablet) {
    font-size: $size-8;
  }

  .preview.is-small & {
    font-size: $size-8;
  }
}

.preview-title {
  font-family: $family-secondary;
  font-size: $title-size-5;
  line-height: 1.4;
  margin-bottom: 0 !important;

  @include until($tablet) {
    font-size: $title-size-6;
  }

  .preview.is-small & {
    font-size: $title-size-7;
    min-height: 2.25em;
  }
}

.preview-subtitle {
  font-weight: 600;
}


/* Previews mit Bild über die ganze breite */


/* Previews mit Bild über die ganze breite -ausser Bildpolitik */
.is-big .preview .preview-subtitle {
  font-size: $title-size-7;
  font-family: $family-secondary;
}

/* Previews Bildpolitik mit Bild über die ganze breite */
.preview.is-big, .is-big .preview {
  padding-bottom: 3.1rem;

  .image {
    margin-bottom: 4.5%;
  }

  .preview-header-text {
    min-height: 0;
    margin-bottom: 30px;
  }
}

.preview.is-big {
  .title {
    font-size: $title-size-4;

    @include until($desktop) {
      font-size: $title-size-7;
    }
  }

  .preview-subtitle {
    font-size: $title-size-6;
    font-family: $family-secondary;

    @include until($desktop) {
      font-size: $title-size-8;
      margin-top: 0.32rem;
    }
  }
}

.preview-teaser {
  text-align: center;
  font-size: $size-7;
  line-height: 1.26;

  .content {
    font-size: inherit;
  }

  .preview.is-small & {
    font-size: $size-8;
    text-align: left;
  }

  @include until($tablet) {
    font-size: $size-8;
  }
}

.is-big .preview .preview-teaser {
  font-size: $size-7;
}

.preview-readmore {
  text-decoration: underline;
  text-decoration-skip: objects spaces;
  text-decoration-skip-ink: auto;
  margin-top: auto;
}

/* Preview mit bild links und Text rechts */
.preview-horizontal {
  .preview-title {
    line-height: $line-height-5;
  }

  .preview-header-text .preview-subtitle {
    line-height: $line-height-7;
    font-size: $title-size-7;
    font-family: $family-secondary;
  }

  @include from($tablet) {
    flex-direction: unset;
    .image {
      min-width: 45%;
      max-width: 45%;
      margin-bottom: 0;
    }
    .preview-authors {
      margin-bottom: 0.3em;
      margin-top: -8px;
    }
    figure{
      margin-right: 20px;
    }
  }
}

@include until($tablet) {
  .mx-edge-tablet .preview .image, .mx-edge-tablet .ix-noborder-tablet .image {
    margin-left: -$spacing-edge;
    margin-right: -$spacing-edge;
  }
}
