@import '../../node_modules/bulma/sass/utilities/all';

$primary: #000;
$primary-invert: findColorInvert($primary);

$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;
$button-color: black;
$button-disabled-border-color: black;

$navbar-height: 2.526315789473684rem;

$size-1: 8.74rem;  // 166px
$size-2: 6.42rem;  // 122px
$size-3: 4.74rem;  //  90px
$size-4: 3.47rem;  //  66px
$size-5: 2.53rem;  //  48px
$size-6: 1.84rem;  //  35px
$size-7: 1.37rem;  //  26px
$size-8: 1rem;     //  19px
$size-9: 0.73rem;  //  14px
$title-size-1: 7.16rem;  // 136px
$title-size-2: 5.21rem;  //  99px
$title-size-3: 3.89rem;  //  74px
$title-size-4: 2.84rem;  //  54px
$title-size-5: 2.05rem;  //  39px
$title-size-6: 1.54rem;  //  29px
$title-size-7: 1.11rem;  //  21px
$title-size-8: 0.84rem;  //  16px
$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8 $size-9;
$title-sizes: $title-size-1 $title-size-2 $title-size-3 $title-size-4 $title-size-5 $title-size-6 $title-size-7 $title-size-8;
$size-large: $size-7;

$line-height-4: 1.2;
$line-height-5: 1.25;
$line-height-6: 1.33;
$line-height-7: 1.4;


$text: #000;
$text-strong: $text;
$title-color: $text;
$subtitle-color: $text;
$size-text: $size-7;
$body-font-size: 19px;
$body-family:
  'Crimson Pro', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica',
  'Arial', sans-serif;

$radius: 0;
$radius-small: 0;
$radius-large: 0;
$input-radius: 0;
$control-radius: 0;
$control-radius-small: 0;
$input-background-color: #ebebeb;
$input-border-color: transparent;
$checkbox-border-width: 1px;
$button-border-color: $primary;
$button-hover-border-color: $primary;
$floating-in-height: 3.75rem;
$help-size: $size-text;
$control-border-width: .2em;
$control-padding-vertical: calc(0.75rem - #{$control-border-width});
$control-padding-horizontal: calc(1rem - #{$control-border-width});
$checkbox-size: 1.5em;
$button-border-width: 1px;

$family-primary: $body-family;
$family-secondary: 'League Spartan', sans-serif;
$crimson-pro-ascender: 918/1024;
$crimson-pro-descender: 220/1024;
$league-spartan-ascender: 1400/2000;
$league-spartan-descender: 440/2000;

$spacing-shortcuts: ('margin': 'm', 'padding': 'p');
$spacing-0: 0;
$spacing-1: (10px / $body-font-size * 1rem);
$spacing-2: (20px / $body-font-size * 1rem);
$spacing-3: (30px / $body-font-size * 1rem);
$spacing-4: (40px / $body-font-size * 1rem);
$spacing-5: (50px / $body-font-size * 1rem);
$spacing-6: (60px / $body-font-size * 1rem);
$spacing-values: (
  "0": $spacing-0,
  "1": $spacing-1,
  "2": $spacing-2,
  "3": $spacing-3,
  "4": $spacing-4,
  "5": $spacing-5,
  "6": $spacing-6,
);

$spacing-edge: (24px / $body-font-size * 1rem);

// We want to pull up the content body by 200px on a full hd display
// and use that as a reference for other viewports.
$pull-up-distance: ((200 / 1920) * 100 * 1vw);

$green: #7E8300;
$success: $green;
$success-invert: findColorInvert($success);
$success-light: findLightColor($success);
$success-dark: findDarkColor($success);
$red: red;
$danger: $red;
$danger-invert: findColorInvert($danger);
$danger-light: findLightColor($danger);
$danger-dark: findDarkColor($danger);
$colors: mergeColorMaps(
    (
      //'white': ($white, $black),
      'black': ($black, $white),
      'light': ($light, $light-invert),
      //'dark': ($dark, $dark-invert),
      //'primary': ($primary, $primary-invert, $primary-light, $primary-dark),
      //'link': ($link, $link-invert, $link-light, $link-dark),
      //'info': ($info, $info-invert, $info-light, $info-dark),
      'success': ($success, $success-invert, $success-light, $success-dark),
      //'warning': ($warning, $warning-invert, $warning-light, $warning-dark),
      'danger': ($danger, $danger-invert, $danger-light, $danger-dark)
    ),
    $custom-colors
);

$navbar-item-hover-color: #676767;
$link-highlight: #fdf2be;
$hr-background-color: none;
$hr-height: 0;
$table-foot-cell-border-width: 1px 0 0 0;
$table-head-cell-border-width: 0 0 1px !default;

$issue-preview-image-threshold: 900px;
