@import '../variables';

.grid {
  display: grid;
  gap: $spacing-3;

  > .field {
    // spacing is controlled through gap, no margin necessary
    margin-bottom: 0;
  }
}

.grid.is-centered {
  align-items: center;
}

@include until($tablet) {
  .grid.is-centered-mobile{
    justify-items: center;
    text-align: center;
  }
}


.grid.is-form {
  grid-template-columns: 180px 180px;

  @include until($tablet) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-flow: column;
  }
}

.grid:not(.is-mobile.is-mobile) {
  @media (max-width: 768px) {
    grid-template-columns: 1fr !important;
    // no !important here so that manual g-2-mobile settings work
    gap: 0;
  }
}

.grid.is-2 {
  grid-template-columns: 1fr 719px;
  gap: $spacing-6;
  justify-content: end;
}

.grid.is-2-alt {
  gap: $spacing-6;
  justify-content: end;
  grid-template-columns: 1fr 678px;
}

.grid.is-33_66 {
  gap: $spacing-6;
  justify-content: end;
  grid-template-columns: 1fr 2.1fr;
}


.grid.is-3 {
  grid-template-columns: 1fr 670px 1fr;
  gap: $spacing-6;
}

$equal-grid-sizes: 1 2 3 4;

@each $grid-size in $equal-grid-sizes {
  .is-equal-#{$grid-size} {
    grid-template-columns: repeat($grid-size, minmax(0, 1fr));
  }

  @include until($tablet) {
    .is-equal-#{$grid-size}-desktop {
      grid-template-columns: minmax(0, 1fr);
    }

    .is-equal-#{$grid-size}-tablet {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  @media screen and (min-width: 520px) and (max-width: 768px) {
    .is-equal-#{$grid-size}-mobile-lg {
      grid-template-columns: repeat($grid-size, minmax(0, 1fr));
    }
  }

  @include from($tablet) {
    .is-equal-#{$grid-size}-tablet {
      grid-template-columns: repeat($grid-size, minmax(0, 1fr));
    }
  }

  @include from($desktop) {
    .is-equal-#{$grid-size}-desktop {
      grid-template-columns: repeat($grid-size, minmax(0, 1fr));
    }
  }
}
