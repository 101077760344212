.title,
.subtitle {
  font-weight: bold;
  text-align: center;
  hyphens: auto;
  line-height: 1.35;
}

.title:not(.is-spaced) + .subtitle {
  margin-top: unset;
}
