:root {
--footer-color: #EBEBEB;
}
footer{
    color: var(--footer-color);
    max-width: 1160px;
    margin: 0px auto 0 auto;
    padding-top: 60px;
    border-top: 1px solid black;
}

footer .bg_color{
    background-color: #676767;
}

.footer-menu{
    margin: 0 auto;
    grid-column-gap: 0px;
}

.footer-menu li a{
    color: var(--footer-color)
}
.footer-menu li{
    list-style-type: none;
}

@include from($desktop){
    .footer-menu{
        max-width: 670px;
    }
    .footer-menu .has_sub{
        border-top: 1px solid var(--footer-color);
        border-bottom: 1px solid var(--footer-color);
    }
}

@include until($desktop){
    footer{
        vertical-align: center;
    }
    .menu-row{
        text-align: center;
    }
}

@include until($tablet){
    .footer-menu{
        max-width: 250px;
    }
 
}
