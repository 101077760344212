.teaser {
  font-size: 2.75rem;
  line-height: 1.125;
  text-align: center;
  display: block;
  width: 100%;
  hyphens: auto;

  &, &:hover {
    color: $text;
  }

  &.is-large {
    padding: 3rem 0;
  }

  &.is-league {
    font-family: $family-secondary;
    line-height: 1.333;

    @include until($tablet) {
      font-size: $title-size-7;
    }

    @include from($tablet) {
      font-size: $title-size-5;
    }
  }
}

.teaser-readmore {
  .teaser:hover &,
  .teaser:active & {
    text-decoration: underline;
  }
}
