.story article{
  padding-bottom: $spacing-5;
}

.article {
  background: white;
  margin: 0 auto $spacing-4 auto;
  position: relative;
}

.article-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  /** needed for pull-up-mobile */
  background: white;
}

.article-header .title {
  line-height: 1.27;

  @include from($tablet) {
    line-height: 1.2;
  }
}

.article-plan-fulfillment {
  padding: calc(1rem + 83px + 1rem) 2rem 1rem 2rem;
  background: url('../images/coffee.svg') no-repeat 50% 1em;
  background-size: auto 83px;
  font-weight: bold;
  width: 100%;
  text-align: center;

  @include until($tablet) {
    background-size: auto 58px;
    padding: calc(1rem + 58px + 1rem) 0 1rem 0;
  }
}

.article-publication {
  width: 100%;
  padding: .55em 0;
  text-align: center;
}

.article-body {
  margin: 0 auto;
  max-width: 940px;

  .article:not(.is-narrow) &,
  &.is-fullwidth {
    max-width: none;
  }

  h2 {
    line-height: 1.3;
  }
}

article.article {
  section.is-type-paragraph.is-last > p:last-child::after,
  section.is-type-html.is-last > p:last-child::after,
  section.is-type-paragraph-with-dropcap.is-last > p:last-child::after {
    background: url('../images/logo-short.svg') no-repeat;
    background-position: bottom -0.1em left 0;
    background-size: auto 0.9em;
    display: inline-block;
    content: "";
    width: 1em;
    height: 1em;
    margin-left: 0.7em;
    margin-bottom: -0.05em;
  }
}

.article-authors {
  font-size: $size-7;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include until($desktop) {
    margin-top: 1.45rem !important;
  }
}

.article-content .grid aside:first-child {
  align-self: flex-start;
}

.article-body :first-child {
  &.is-type-paragraph,
  &.is-type-paragraph-with-dropcap {
    margin-top: -($size-text / 2.25);
  }
}

#article-start {
  order: 1000;
}

.article {
  @include until($tablet) {
    aside:first-of-type {
      border-bottom: 1px solid;
    }

    aside:last-of-type:not(:first-of-type) {
      border-top: 1px solid;
    }

    aside a.is-issue {
      display: block;
      width: 128px;
      margin: 0 auto;
    }

  }
}
