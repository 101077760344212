@import '../../node_modules/bulma/sass/utilities/all';
@import './variables';
@import '../../node_modules/bulma/bulma.sass';
@import '~buefy/src/scss/buefy';

$table-colors: (
  'black': ($black, $white),
);

[class^="is-size-"],
[class*="is-size-"] {
  font-family: $family-primary !important;
}

[class^="is-league-"],
[class*="is-league-"] {
  font-family: $family-secondary !important;
}

@each $size in $title-sizes {
  $i: index($title-sizes, $size);
  .is-league-#{$i} {
    font-size: $size !important;
  }

  @include until($tablet) {
    .is-league-#{$i}-mobile {
      font-size: $size !important;
    }
  }
}

@each $size in $sizes {
  $i: index($sizes, $size);
  @include until($tablet) {
    .is-#{$i}-mobile {
      font-size: $size !important;
    }
  }
}

@include until($tablet) {
  @each $property, $shortcut in $spacing-shortcuts {
    @each $name, $value in $spacing-values {
      // All directions
      .#{$shortcut}-#{$name}-mobile {
        #{$property}: $value !important;
      }

      // Cardinal directions
      @each $direction, $suffix in $spacing-directions {
        .#{$shortcut}#{$suffix}-#{$name}-mobile {
          #{$property}-#{$direction}: $value !important;
        }
      }

      // Horizontal axis
      @if $spacing-horizontal != null {
        .#{$shortcut}#{$spacing-horizontal}-#{$name}-mobile {
          #{$property}-left: $value !important;
          #{$property}-right: $value !important;
        }
      }

      // Vertical axis
      @if $spacing-vertical != null {
        .#{$shortcut}#{$spacing-vertical}-#{$name}-mobile {
          #{$property}-top: $value !important;
          #{$property}-bottom: $value !important;
        }
      }
    }
  }
}

@include until($desktop) {
  @each $property, $shortcut in $spacing-shortcuts {
    @each $name, $value in $spacing-values {
      // All directions
      .#{$shortcut}-#{$name}-tablet {
        #{$property}: $value !important;
      }

      // Cardinal directions
      @each $direction, $suffix in $spacing-directions {
        .#{$shortcut}#{$suffix}-#{$name}-tablet {
          #{$property}-#{$direction}: $value !important;
        }
      }

      // Horizontal axis
      @if $spacing-horizontal != null {
        .#{$shortcut}#{$spacing-horizontal}-#{$name}-tablet {
          #{$property}-left: $value !important;
          #{$property}-right: $value !important;
        }
      }

      // Vertical axis
      @if $spacing-vertical != null {
        .#{$shortcut}#{$spacing-vertical}-#{$name}-tablet {
          #{$property}-top: $value !important;
          #{$property}-bottom: $value !important;
        }
      }
    }
  }
}

@each $name, $value in $spacing-values {
  .g-#{$name} {
    gap: $value !important;
  }

  @include until($tablet) {
    .g-#{$name}-mobile {
      gap: $value !important;
    }
  }
}

@include until($desktop) {
  @each $property, $shortcut in $spacing-shortcuts {
    .#{$shortcut}x-edge-tablet {
        #{$property}-left: $spacing-edge !important;
        #{$property}-right: $spacing-edge !important;
    }
  }
}

.button.is-secondary {
  @extend .button, .is-text;
  text-decoration: none !important;
}

.content {
  font-size: $size-text;
  line-height: 1.45;
  hyphens: auto;

  a:not(.button) {
    text-decoration: underline;
  }

  p:not(:last-child) {
    margin-bottom: 1.7rem;
  }
}

.help-block {
  color: #666;
}

.input,
.select select,
.textarea,
.taginput .taginput-container.is-focusable {
  box-shadow: none !important;
}

.tooltip.tooltip {
  &::after {
    max-width: 90vw;
  }

  @media (max-width: 600px) {
    &.is-bottom::after {
      left: 0;
      right: 0;
      transform: none;
      white-space: normal;
      text-align: center;
    }
  }
}

.select::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 3.25rem;
  z-index: 1;
  pointer-events: none;
  background: black;
}

.select:not(.is-multiple):not(.is-loading)::after {
  $size: 1em;
  background: #A0A0A0;
  border: none;
  right: calc(3.25rem / 2 - #{$size} / 2);
  transform: translateY(-50%) rotate(180deg);
  transform-origin: 50% 50%;
  height: $size;
  width: $size;
  clip-path: polygon(50% 35%, 0% 100%, 100% 100%);
  top: calc(50% + .25em);
  transition: transform;
}

.select:active:not(.is-multiple):not(.is-loading)::after {
  top: calc(50% - .25em);
  transform: translateY(-50%) rotate(0deg);
}

.label {
  font-size: $size-7;
  font-family: $family-secondary;
}

.checkbox {
  font-size: $size-text;

  .check {
    background-size: 75% !important;
  }

  .control-label {
    padding-left: ($control-padding-horizontal / 2) !important;
  }

  &.b-checkbox input[type=checkbox]:focus + .check,
  &.b-checkbox input[type=checkbox]:focus:checked + .check {
    box-shadow: none;
    outline: 2px solid black;
  }

  @include until($tablet) {
    font-size: $size-8;
  }
}

.label,
.button {
  font-family: $family-secondary;
}

.button.is-serif {
  font-family: $family-primary;
  font-style: italic;
}

.button.is-short {
  height: auto;
  padding: 0 0.5em;
}

.button > span {
  position: relative;
  top: .115em;
}

.button.is-large > span {
  font-size: 1.25rem;
}

.field {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &.is-floating-label,
  &.is-floating-in-label {
    .label {
      top: .75rem;
      left: calc(#{$control-padding-horizontal} + #{$control-border-width});
      line-height: 1.75em;
    }
  }
}

.container.is-narrow {
  max-width: 940px !important;
}

@include until($tablet) {
  .button.is-fullwidth-mobile {
    display: flex;
    width: 100%;
  }
}

.table td,
.table th {
  border-color: black;
}

.table.is-center th,
.table.is-center td {
  vertical-align: middle;
}

.table tbody.is-bordered {
  & > tr {
    td, th {
      border: none;
    }
  }

  &:not(:last-child) > tr:last-child {
    td, tr {
      border-bottom: 1px solid black;
    }
  }
}

.table.has-no-side-padding {
  td:first-child, th:first-child {
    padding-left: 0;
  }

  td:last-child, th:last-child {
    padding-right: 0;
  }
}
