.nav-siblings {
  background: #ebebeb;
  width: calc(100% + 2 * #{$spacing-edge});
  padding: 0 #{$spacing-edge};

  .navbar-item {
    font-size: $title-size-8;
    padding: 1em 1em 0.8em;

    border-top: 1px solid black;
  }

  .navbar-item > span {
    display: unset;

  }
}
