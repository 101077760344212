.issue-reference-horizontale {
  grid-template-columns: 1fr 180px 1fr;
  gap: $spacing-6;
  border-bottom: 1px solid #808080;

  @include until($tablet) {
    :nth-child(1) {
      order: 2;
    }
    :nth-child(2) {
      order: 1;
    }
  }
}
