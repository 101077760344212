.bildpolitik {
    .small-image {
      max-width: 572px;
      margin: 0 auto;
    }

    .large-image {
      max-width: 1160px;
    }

    .large-image .caption {
      margin-top: 50px;
      margin-bottom: 0;
      @include from($tablet) {
        margin-right: 100px;
        margin-left: 100px;
      }
    }

    .position.article .article-body {
      max-width: unset;
    }

    .caption {
      margin-top: 50px;
      hyphens: auto;
    }

    .no_caption {
      margin-top: 60px;
     }

    .block-TwoImages, .block-OneImage {
      margin-top: 80px;
      border-bottom: solid 1px #808080;
      padding-bottom: 20px;
    }
    .article-body > *:not(.block-TwoImages):not(.block-OneImage){
      max-width: 670px;
      margin: auto;
      margin-top: $spacing-5;
      padding-bottom: $spacing-5;
      border-bottom: solid 1px #808080;
    }
  }

  @include from($desktop) {
    .bildpolitik .block-TwoImages{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
    }
  }
