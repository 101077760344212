.article-archive {
  .preview .wide {
    flex-direction: unset;
  }

  .preview .title {
    text-align: left;
  }

  .preview-header-text {
    margin: 0;
    flex-grow: 1;
  }

  .preview-header {
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .preview-teaser {
    text-align: left;
  }

  .article-column + .article-column {
    border-top: 1px solid black;
    padding-top: 2em;
  }

  .preview-teaser, .preview-authors{
    font-size: $size-8;
  }
}
