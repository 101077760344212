.align-right {
  margin: 0 0 0 auto;
}
@include from($desktop) {
  .align-left-desktop {
    margin: 0 auto 0 0;
  }
}

.is-numeric {
  font-variant: oldstyle-nums tabular-nums slashed-zero;
}

.no-wrap {
  white-space: nowrap;
}

.m-auto {
  margin: auto;
}

@include until($desktop) {
  .mr-auto-mobile {
    margin-right: auto;
  }
}

.w-05 {
  max-width: 532px
}


.w-1 {
  max-width: 964px
}

.w-2 {
  max-width: 1160px;
}

.w-3 {
  max-width: 1920px;
}

.w-4 {
  max-width: 2560px;
}

.w-100 {
  width: 100%;
}

.bt-1 {
  border-top: 1px solid black;
}

.bb-1 {
  border-bottom: 1px solid black;
}

.bt-2 {
  border-top: 2px solid black !important;
}

.bb-2 {
  border-bottom: 2px solid black !important;
}

.lh-2 {
  line-height: 1.20;
}

.mh-1 {
  min-height: 2.5rem;
}

.mh-2 {
  min-height: 5rem;
}

@include from($desktop) {
  .mr-7-desktop {
    margin-right: 4.5rem;
  }
}


.interact-underline {
  .interact-target:hover &,
  .interact-target:active &,
  .interact-target:focus & {
    text-decoration: underline;
  }
}

.unstyled {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.overflow-text-ellipsis {
  overflow: hidden;
  display: block;
  max-width: 100%;
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
}

.leading-none {
  line-height: 1 !important;
}

.is-underlined {
  text-decoration: underline;
}
