.position {
  .article-header {
    align-items: unset;
  }

  .article-authors {
    justify-content: unset;
  }

  .teaser, .title, .subtitle {
    text-align: unset;
  }

  .teaser {
    @include until($tablet) {
      padding-left: $spacing-3;
      padding-right: $spacing-3;
    }
  }

  .grid.is-3 {
    grid-template-columns: 1fr 180px;
    gap: $spacing-5;
  }

  &.article .article-body {
    max-width: 827px;
    margin: 0 0 0 auto;
  }
  .article-body, .article-authors, .title, .subtitle  {
    width: -webkit-fill-available;
    width: stretch;
    width: -moz-available;
  }

  blockquote{
    text-align: left;
  }
}
