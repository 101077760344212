.pull-up-desktop {
  @media (min-width: $widescreen) {
    position: relative;
    top: -$pull-up-distance;
    margin-bottom: -$pull-up-distance;
  }
}

@function calcPullUpDistance($padding-top, $font-ascender, $font-descender, $font-size, $line-height) {
  @return $padding-top + $font-ascender * $font-size + ($line-height - ($font-ascender + $font-descender))/2 * $font-size
}

@include until($desktop) {
  .pull-up-mobile {
    position: relative;
    $distance: calcPullUpDistance(1.45rem, $league-spartan-ascender, $league-spartan-descender, $title-size-5, 1.27);
    top: -$distance;
    margin-bottom: -$distance;
  }

  .issue .article .pull-up-mobile {
    $distance: calcPullUpDistance($spacing-2, $crimson-pro-ascender, $crimson-pro-descender, $size-7, 1.5);
    top: -$distance;
    margin-bottom: -$distance;
  }

  .article .pull-up-mobile {
    $distance: calcPullUpDistance(1.45rem, $crimson-pro-ascender, $crimson-pro-descender, $size-8, 1.5);
    top: -$distance;
    margin-bottom: -$distance;
  }
}
