.aside {
  padding: 1rem 0;

  &:first-child {
    padding-top: 0;
  }

  .block.is-type-paragraph {
    font-size: $size-8;
  }

  &:not(:last-child) {
    border-bottom: 1px solid black;
  }

  .content {
    @include until($tablet) {
      line-height: 1.26;
    }
  }

  .content p:not(:last-child) {
    margin-bottom: 1.26em;
  }
}

.aside-title {
  font-family: $family-secondary;
  font-size: $size-9;
  margin-bottom: $spacing-3;

  @include from($tablet) {
    font-size: $title-size-8;
    margin-bottom: $spacing-5;
  }
}
