.rich-text {
  hyphens: auto;

  > h2 {
    hyphens: manual;
  }
}

.rich-text > blockquote {
  @extend .block, .is-type-blockquote;
  background: transparent;
}

.rich-text-image {
  width: calc(50% - .75rem);
  margin: 0 0 1.5rem 0 !important;

  img {
    display: block !important;
  }

  &.does-open img {
    cursor: pointer;
  }
}
