.footnotes {
  ol {
    list-style: none;
  }

  ol li p:first-of-type {
    display: inline;
  }
}

.footnote[id],
.footnotes [id] {
  margin-top: -$navbar-height;
  padding-top: $navbar-height;
}

sup.footnote {
  font-family: $family-secondary;
  font-size: 50%;
}
