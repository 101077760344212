.form-section {
  margin-bottom: $size-4;

  .columns:not(:last-child) {
    margin-bottom: 0;
  }

  &.is-fullwidth {
    max-width: none;
  }
}

.input.is-danger, .is-danger > .input  {
  border: 6px solid red;
}

.help.is-danger {
  font-size: $title-size-7;
  font-family: $family-secondary;
}
