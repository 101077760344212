.navbar {
  max-width: 1160px;
  margin: auto;

  --navbar-active-color: black;
  --navbar-item-background: transparent;
  --navbar-item-color: black;
  --navbar-item-active-color: black;
  --navbar-item-active-background: transparent;
}

.navbar.is-black {
  --navbar-active-color: white;
  --navbar-item-color: white;
  --navbar-item-active-color: #ffffbf;

  .navbar-item span::after {
    display: none;
  }
}

.navbar.is-fixed-top {
  width: 1160px;
  max-width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid black;
}

.navbar.is-foreground {
  z-index: 10000;
  background: white;
  padding-top: 40px;
}

.navbar.is-background {
  z-index: 9000;
}

.navbar-item {
  font-family: $family-secondary;
  font-weight: bold;
  font-size: $size-9;
  text-align: center;
  flex: 1 1 auto;
  justify-content: center;
  white-space: nowrap;
  padding: 0 $size-7;
  align-items: stretch;
  background: var(--navbar-item-background) !important;
  color: var(--navbar-item-color) !important;
  margin: 0 !important;
  box-shadow: none !important;

  &:focus,
  &:hover,
  &:active {
    outline: none;
    background: inherit;
    color: inherit;
  }

  &.is-active {
    color: var(--navbar-item-active-color) !important;
    background: var(--navbar-item-active-background) !important;
  }

  &:focus-visible {
    outline: 2px solid grey;
  }

  &.is-square {
    padding: 0;
    width: $navbar-height;
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > span {
    position: relative;
    padding: calc(0.5rem + 3px) 0 0.4rem;
    display: flex;
    align-items: center;
    width: -webkit-min-content;
    min-height: 27.9px;

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 7px;
      display: block;
      width: 100%;
    }
  }

  .navbar &.is-active > span::after {
    background: var(--navbar-active-color);
  }
}

details.navbar-item {
  padding: 0 !important;

  &[open] > .navbar-item > span::after {
    background: var(--navbar-item-active-color);
  }

  &[open] > summary::before {
    //close details when clicking outside
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: default;
    z-index: -1;
  }
}

summary.navbar-item {
  outline: none;
  color: inherit;
}

.navbar-start {
  justify-content: space-evenly;
  flex: 1;
}

.navbar-logo {
  display: flex;
  align-items: center;
  max-width: 108px;

  .icon {
    width: auto;
    height: 100%;
  }

  svg {
    transform: none;
  }

  > span {
    font-family: $family-primary;
    font-weight: normal;
    font-style: italic;
    text-transform: uppercase;
    color: #808080;
    font-size: .87rem;
    line-height: 1.125;
  }
}

.navbar.with-dialog-controls {
  .navbar-brand {
    $min-icon-width: 2rem;
    grid-template-columns: minmax($min-icon-width, 1fr) 6fr minmax($min-icon-width, 1fr);

    > :first-child,
    > :last-child {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(auto-fit, $min-icon-width);
    }

    > :first-child {
      justify-content: start;
    }

    > :last-child {
      justify-content: end;
    }
  }
}

.navbar.is-dialog-bar {
  justify-content: center;

  &, .navbar-brand {
    max-width: 100vw;
    width: 100%;
  }

  .navbar-brand {
    width: 100%;
    align-items: center;
    position: relative;
    top: -1px;
    gap: 0;
    justify-content: space-between;
    padding: 0 $size-8;
  }

  .title {
    position: relative;
    top: 4px;
    line-height: 2;
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: center;
  }

  &.is-black .title {
    color: white;
  }
}

summary.navbar-item,
.navbar-item > summary {
  list-style-type: none;
  box-sizing: border-box;
  height: 100%;
  cursor: pointer;
  user-select: none;
  text-align: -webkit-center;

  &::-webkit-details-marker {
    display: none;
  }
}

.navbar-burger {
  display: flex;
  align-items: stretch;
  padding: 0 .75rem;
  width: calc(#{$size-7} * 2 + 24px - 2px);

  .navbar-burger-close,
  .navbar-burger-open {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 100%;
  }

  details[open] & {
    background: #676767;

    .navbar-burger-open {
      display: none;
    }
  }

  details:not([open]) & {
    .navbar-burger-close {
      display: none;
    }
  }
}

.navbar-submenu {
  top: 100%;
  min-width: fit-content;
  left: 50%;
  transform: translateX(-50%);
  --navbar-item-color: white;
  --navbar-item-active-color: #ffffbf;

  @include from($desktop) {
    box-shadow: 0 6px 9px #00000066;
    background: black;
  }
}

.navbar-mobile-nav {
  --navbar-item-padding: #{$size-8};
  --navbar-item-active-color: white;

  position: fixed;
  left: 0;
  right: 0;
  z-index: 2300;
  top: $navbar-height;
  bottom: 0;
  background: #282828;
  padding: $size-7;
  overscroll-behavior: contain;
  overflow: auto;

  .navbar-submenu {
    .navbar-item {
      --navbar-item-padding: 12px !important;

      &:first-child span {
        padding-top: 0;
      }

      &:last-child span {
        padding-bottom: 0;
      }
    }

    padding-left: $size-7;
    position: static;
    width: auto;
    min-width: 0;
    transform: none;
    padding-bottom: var(--navbar-item-padding);
  }

  .navbar-item {
    text-align: left;
    justify-content: flex-start;
    font-size: 0.9rem;
    margin-left: $size-7;
    padding: 0;

    .navbar-item:first-child {
      margin-top: $size-9;
    }

    > span {
      padding-top: calc(var(--navbar-item-padding) + 1px);
      padding-bottom: calc(var(--navbar-item-padding) - 1px);
      color: white;

      &::after {
        display: none
      }
    }

    &.is-active > span {
      color: #ffffbf;
    }
  }

  > .navbar-item {
    margin-left: 0;

    &:not(:last-child) {
      border-bottom: 1px solid #A0A0A0;
    }
  }

  details[open] > summary.navbar-item.is-active > span {
    color: inherit;
  }

  [data-dropdown-target] {
    position: static;
  }

  input, button {
    box-sizing: border-box;
  }
}

.navbar.is-expanded {
  flex-direction: column;

  .navbar-brand > .navbar-item > .navbar-logo {
    flex-direction: column;
  }

  .navbar-menu {
    flex-direction: column;
    margin-top: 8px;
  }

  .navbar-start {
    border-bottom: 1px solid black;
    width: 100%;
  }

  .navbar-start,
  .navbar-end {
    margin: auto;
  }
}

.breadcrumbs {
  display: flex;
  font-size: $size-9;
  color: #707070;
}

.breadcrumb {
  color: inherit;
  display: block;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
  margin: 0 !important;
  font-size: inherit;

  &.is-active {
    color: black;
  }

  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }

  &:focus-visible {
    box-shadow: $link-highlight 0 -2em 0 inset;
  }

  &:not(:last-child)::after {
    content: '\00A0/\00A0';
    color: #707070;
  }

  --breadcrumb-active-color: #{$black};

  &:last-child {
    color: var(--breadcrumb-active-color);
  }
}

.navbar-item.has-search {
  width: 155px;
}

.navbar-search {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  width: 28.5px;
  margin: auto;
  transition: width .2s ease-out;

  &:focus-within {
    width: 155px;
  }

  > span {
    display: flex;
    align-items: center;
  }

  input {
    margin-left: $size-9;
    min-width: 0;
    border: none;
    background: transparent;
    color: var(--navbar-item-color);
    font-size: $size-8;

    &:focus {
      outline: none;
    }
  }
}
