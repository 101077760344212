:root {
  font-size: $body-font-size;
  scroll-behavior: smooth;
  overflow: visible;
}

html {
  background-color: unset;
}

body {
  background-color: white;
  overflow: auto;
  overflow-y: scroll;

  &.hide-overflow {
    overflow: hidden;
  }

  @include until($tablet) {
    // don’t use margin here or otherwise they might collapse with other elements
    padding-top: $navbar-height;
  }
}

p:empty {
  // this helps with empty paragraphs in posts that should not interfere with the spacing
  // of elements especially at the start of the page (i.e. in image politics articles).
  margin: 0 !important;
}

dialog {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  width: -webkit-fit-content;
  height: -webkit-fit-content;
  margin: auto;
  border-width: initial;
  border-style: solid;
  border-color: initial;
  border-image: initial;
  background: white;
  z-index: 50;
}

table th {
  font-family: $family-secondary;
  font-size: $title-size-8;
}

table th:not([align]), .table th:not([align]) {
  text-align: left; /* for safari */
}

table td {
  font-size: $size-text;
}

::-webkit-resizer {
  appearance: none;
  width: 5px;
  height: 5px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNIDI0LDAgMCwyNCBoIDI0IHoiIGZpbGw9IiNBMEEwQTAiLz48L3N2Zz4K) no-repeat;
  background-size: cover;
}

.content a {
  box-shadow: $link-highlight 0 -0.5em 0 inset;
  transition: box-shadow .25s ease;
  text-decoration: none !important;
  border-bottom: none;

  &:focus,
  &:hover {
    box-shadow: $link-highlight 0 -2em 0 inset;
  }
}

ul li::marker {
  font-size: 80%;
}

.content {
  ol {
    counter-reset: list;
    margin-left: unset;
  }

  ol > li {
    list-style: none;
    position: relative;
  }

  ol > li:before {
    counter-increment: list;
    content: counter(list, decimal) ") ";
    font-weight: bold;
  }
}

.responsive-object {
  position: relative;
  margin-top: $spacing-5;

  &:not(:last-child) {
    margin-bottom: $spacing-6;
  }
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
