.icon {
  width: 1rem;
  height: 1rem;

  svg {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  path {
    fill: currentColor;
  }
}

.svg-icon-wrapper {
  shape-rendering: geometricPrecision;

  .button & {
    transform-origin: center;
    transform: scale(.75);
  }

  .control > input + .icon &,
  .control > button > .icon & {
    transform-origin: center;
    transform: scale(.5);
  }
}

.svg-icon-wrapper use {
  fill: currentColor;
}
