.reviews {
  padding: $spacing-2 0;
  margin-bottom: 3rem;
  hyphens: auto;

  &.columns {
    margin-top: unset;
  }

  .column {
    padding: $spacing-1;
  }
}

figure.image.is-review {
  text-align: center;

  img {
    height: 7rem;
    width: auto;
    margin: auto;
    border: 1px solid black;
  }

  figcaption {
    margin-top: 1rem;
    font-size: $size-8;
  }

  strong {
    font-weight: 600;
  }
}
